.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #212518;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .navbar-brand {
    margin-right: auto;
    margin-left: 20px;
  }
  
  .navbar-name {
    padding-left:15px;
    font-size: 24px;
    font-weight: bold;
    color: #c1d8c0;
  }
  
  .navbar-links a {
    margin: 0 10px;
    color: #c1d8c0;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 125%;
  }
  
  .navbar-links a:hover {
    color: #505d46;
  }
  