.profile-picture {
  border-radius: 50%; /* This makes the image appear as an oval shape */
  margin-top: 10%;
}

.hero-header{
    color: #ccf5f5
}

.hero-container{
    border:4px solid #212518;
    border-radius: 30px;
    padding-bottom: 15px;
    margin-right: 100px;
    margin-left: 100px;
    background-color: #212518;
}
.homepage-container{
    background-color: #ccf5f5;
    min-height: 100vh; /* Set minimum height to 100% of viewport height */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 260px;
    padding-bottom: 20px;
}

.homepage-container img{
    padding-top: 15px;
}

.about-me {
    margin-left:20%;
    margin-right:20%;
    margin-top:2%;
    justify-content: center;
    border: 4px solid #212518;
    border-radius: 30px;
    padding-bottom: 50px;
    background-color: #C1D8C0;
}

.about-me p{
    margin-left: 10%;
    margin-right: 10%;
}

h2{
    font-size:200%;
    color:#C1D8C0;
}

h3{
    font-size:150%;
    color:#C1D8C0;
}

.about-me h3{
    font-size: 155%;
    color:#212518;
}

h4{
    font-size: 150%;
    color:#212518;
}

p {
    font-size: 125%;
    color:#212518;
}

.contact-information{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 69%;
    margin-right: 44px;
}

@media (max-width:880px)
{
    .contact-information{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 69%;
        margin-left: 48px;
    }
    .about-me p{
        margin-left: 10vh;
        margin-right: 10vh;
    }
}

.phone-icon{
    max-width: 160px;
    max-height: 275px;
}

.phone-container,
.email-container,
.other-container{
    display:inline-block;
    border: 4px solid;
    border-color: #212518;
    border-radius: 30px;
    background-color: #c1d8c0;
    margin-top: 8px;
}

.email-icon{
    max-width: 160px;
    max-height: 275px;
}



.emails{
    padding-top:15px;
    margin-left: 4px;
    margin-right: 4px;
}


.icon-container{
    display:flex;
    flex-direction:column;
    align-items: center;
    width: 105px;
    height: 250px;
    gap: 70px;
}
.git-icon{
    max-width: 100px;
    margin-bottom:10px;
    margin-left: -25px;
}

.linked-in-icon{
    max-width: 100px;
    margin-bottom:25px;
    margin-left:-25px;
}

.page-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../img/background.png');
    background-size: cover;
    background-position: center;
    filter: grayscale(35%);
    z-index: -1;
  }

  @media (max-width:650px)
  {
    p{
        font-size:80%
    }
    .about-me{
        margin-left: 10px;
        margin-right: 10px;
    }
  }