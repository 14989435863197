.skills-container {
    position: relative;
    width: 580px;
    height: 580px;
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .skills-container.flipped {
    transform: rotateY(180deg);
  }
  
  .skills-front,
  .skills-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .skills-front {
    background-image: url("../../img/skills.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: red;
  }

  .skills-front:hover{
    transition: 0.4s all;
    filter: blur(5px);
  }
  
  .skills-back {
    background-color: #505d46;;
    color: #ccf5f5;
    transform: rotateY(180deg);
  }
  
  .skills-back p{
    color: #ccf5f5;
  }

  .skills-back ul{
    list-style-type: none ;
    margin: 0;
    padding: 0;
  }

  .skill-header {
    margin-bottom: 10px;
    color: #ccf5f5;
  }

  @media screen and (max-width: 780px) {
    .skills-container {
        width: 70vw;
        height: 450px
    }

    .skill-header{
        margin-top: 0px;
        font-size: 100%;
        color: black;
    }

    .skills-back ul{
        color: black;
        font-size: 80%;
    }

    .skills-back{
        background-color: #c1d8c0;
    }
  }