.portfolio-images{
    max-width: 580px;
    border: 4px solid #212518;
    margin-right: 50px;
    margin-left: 50px;
} 

h1 { 
  padding-top: 40px;
}

.portfolio {
    background-color: #c1d8c0;
    max-width: 100%;
    margin-left: 0;
    padding-left: 8vw;
    padding-right:8vw;
    margin-top: 15px;
    align-items: center;
  }

.portfolio-contents{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right:500px;
  
}
  
  .portfolio h2 {
    font-size: 24px;
    font-weight: bold;
  }
  

  .portfolio-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 90px;
    margin-top: 20px;
  }
  
  .portfolio .image-container {
    margin-right: 20px;
  }
  
  .portfolio .description {
    flex-grow: 1;
  }
  
  .portfolio-container{
    background-color: #ccf5f5;
  }

  .description{
    margin-left: 50px;
    margin-right:50px;
  }

  .description-container{
    justify-content: space-around;
    border: 4px solid #212518;
    border-radius: 30px;
    padding-bottom: 50px;
    background-color: #505D46;
    color: #ccf5f5;
  }

  .portfolio-header{
    color: #ccf5f5;
  }

  @media (max-width:1530px)
  {
    .portfolio-content {
      display: flex;
      flex-direction: column;
    }
    .image-container {
      margin-bottom: 10px;
      margin-top: 10px;
    }
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .portfolio-images{
      max-width: 590px;
      border: 4px solid #212518;
      margin-right: 0px;
      margin-left: 0px;
  } 
  }

@media (max-width:1000px)
{
  .portfolio-images{
    max-width: 390px;
    border: 4px solid #212518;
    margin-right: 0px;
    margin-left: 0px;
} 
.portfolio {
  background-color: #c1d8c0;
  max-width: 100%;
  margin-left:0px;
  padding-left: 8vw;
  padding-right:8vw;
  margin-top: 15px;
  align-items: center;
}
}

@media (max-width:650px)
{
  #portfolio-description{
    font-size: 80%;
    margin-right: 50px;
    margin-left:50px;
  }
  .portfolio-images{
    max-width: 300px;
    border: 4px solid #212518;
    margin-right:5px;
  }
  .portfolio{
    background-color: #c1d8c0;
    max-width: 100%;
    margin-left: 0px;
    padding-left: 0vw;
    margin-top: 15px;
    align-items:center;
  }
  .portfolio-content{
    margin-right:15px;
    margin-left:15px
  }
}