.resume-background {
    background-color: #ccf5f5;
    color:  #212518;
  }
  
  .resume-container {
    max-width: 80vw;
    margin-left: 10vw;
    background-color: #c1d8c0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
  }

.resume-header{
  color: #212518;
}

  .experience-container ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;    
  }

  a{
    height: 50px;
    width: 50px;
  }

  .resume-icon-container{
    flex-direction: row;
    gap: 10px; 
  }

  .pdf-icon{
    height: 50px;
    width: 50px;
  }

  .docx-icon{
    height: 50px;
    width: 50px; 
  }
  .work-container ul {
    margin-top: 10px;
  }
  
  .work-container li:not(:last-child) {
    margin-bottom: 10px;
  }
  

  .bolded{
    font-weight: bold;
    font-size: 150%;
  }

  .italics{
    font-style: italic;
    font-size: 115%;
  }
  
li{
    font-size: 110%
}

.resume-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.education-skills-container {
  display: flex;
  gap: 20px; /* Adds a 10px gap between the tiles */
  flex-wrap: wrap; /* Allows the tiles to wrap to a new line if there's not enough space */
  justify-content: center; /* Centers the tiles horizontally */
  margin-top: 15px;
}

.experience-container{
  margin-bottom: 50px;
  max-width: 1200px;
}

.skills-component {
  display: flex;
  justify-content: space-between;
}

.skills-column {
  flex: 1;
  margin: 70px;
  
}

.skills-column-1{
  margin-left: 170px;
  font-weight: bold;
}

.skills-column-2{
  font-weight: bold; 
  padding-right: 76px;
  padding-left: 76px;
}

.skills-column-3{
  font-weight: bold;
  margin-right: 250px;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 1220px) {
  .skills-component {
    flex-direction: column;
  }

  .skills-column-1{
    margin-left: 0px;
    font-weight: bold;
  }
  
  .skills-column-2{
    font-weight: bold; 
    padding-right: 76px;
    padding-left: 76px;
  }
  
  .skills-column-3{
    font-weight: bold;
    margin-right: 0px;
  }
  
}

.icon-caption{
  margin-top: 0px;
}

