.education-container {
    position: relative;
    width: 580px;
    height: 580px;
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
    cursor: pointer;
  }
  
  .education-container.flipped {
    transform: rotateY(180deg);
  }
  
  .education-front,
  .education-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .education-front {
    background-image: url("../../img/CU.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: red;
  }

  .education-front:hover{
    transition: 0.4s all;
    filter: blur(5px);
  }
  
  .education-back {
    background: black;
    color: #ccf5f5;
    transform: rotateY(180deg);
  }
  
  .education-back p{
    color: #ccf5f5;
  }

  .education-back ul{
    margin: 0;
    padding: 0;
    list-style-type: none ;
  }

  .portfolio-picture{
    padding-top: 0px;
    width:50%;
  }

  .university-name{
    margin-top: 0;
    margin-bottom: 0;
    color: #ccf5f5;
  }

  .tam-link{
    color: #ccf5f5;
  }

  @media screen and (max-width: 780px) {
    .education-container {
        width: 70vw;
        height: auto;
        padding-bottom: 70vw;
        margin-bottom: 90px;
    }

    .portfolio-picture{
        width: 40%;
        height: auto;
    }

    .blur{
      background: rgba(0,0,0,0.2);
      opacity: 0;
    }

    .blur:hover{
      opacity: 1;
      backdrop-filter: blur(5px);
    }

    .blur:hover{
      -webkit-filter: blur(5px);
      filter: blur(5px);
    }

    .university-name{
        font-size: 120%;
        padding-top: 5px;
    }

    .education-description{
        padding-top: 20px; 
        font-size: 100%;
    }

    ul{
        font-size: 90%;
    }
  }

  @media screen and (max-width: 450px)
  {
    .university-name{
        font-size: 100%;
        padding-top: 5px;
    }

    .education-description{
        padding-top: 2%; 
        font-size: 80%;
    }

    .portfolio-picture{
        display: none;
    }

    ul{
        font-size: 70%;
    }
  }